<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <div>
      <div class='table-operator'>
        <a-button type='primary' icon='plus' @click='handleNew({})'>新增</a-button>
        <a-divider type='vertical' />
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
      </div>
      <s-table ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: "100%"}' :data='loadData' :showPagination="false">
        <span slot='qrImgUrl' slot-scope='text, record'>
          <template>
            <img :src='text' width='180px' :preview="record.id" >
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
              <a @click='handleNew(record)'>编辑</a>
              <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                            @confirm='handleDelete(record)'>
                <a href='#'>删除</a>
              </a-popconfirm>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>
    <a-modal title='编辑用户' :destroyOnClose='true' :maskClosable='true' :visible='deliver.visible'
             @cancel='()=>this.deliver.visible=false'
             ok-text='提交' cancel-text='取消' @ok='handleDeliver' :confirmLoading='deliver.loading'>
      <a-form-model ref='form' :model='deliver.form' :rules='deliver.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='账户名称:' prop='name'>
          <a-input v-model='deliver.form.name' />
        </a-form-model-item>
        <a-form-model-item label='简称:' prop='simpleName'>
          <a-input v-model='deliver.form.simpleName' />
        </a-form-model-item>
        <a-form-model-item label='法定代表人:' prop='legal'>
          <a-input v-model='deliver.form.legal' />
        </a-form-model-item>
        <a-form-model-item label='地址:' prop='address'>
          <a-input v-model='deliver.form.address' />
        </a-form-model-item>
        <a-form-model-item label='户名:' prop='accountName'>
          <a-input v-model='deliver.form.accountName' />
        </a-form-model-item>
        <a-form-model-item label='开户行:' prop='accountBank'>
          <a-input v-model='deliver.form.accountBank' />
        </a-form-model-item>
        <a-form-model-item label='账号:' prop='accountNo'>
          <a-input v-model='deliver.form.accountNo' />
        </a-form-model-item>
        <a-form-model-item label='公章：' prop='seal'>
          <a-upload
            list-type='picture-card'
            :file-list="deliver.form.seal ? [{
              uid: '-1',
              name: 'seal',
              status: 'done',
              url: deliver.form.seal,
            }] : []"
            :customRequest='(event) => {handleUpload(event, "seal")}'
            :remove='() => deliver.form.seal = ""'>
            <div v-if="!deliver.form.seal">
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>

        <div style="width: 100%;margin: auto;padding-bottom: 30px;">
          <p style="border-bottom: 1px solid #cccccc;"/>
        </div>

        <a-form-model-item label='支付渠道' prop='paymentChannelType'>
          <a-select v-model="deliver.form.paymentChannelType"  @change="payChannelChange" placeholder="请选择">
            <a-select-option :value=1>
              微信
            </a-select-option>
            <a-select-option :value=2>
              乐刷门店
            </a-select-option>
            <a-select-option :value=3>
              乐刷渠道号
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label='账户名称' prop='weixinSubMchid'>
          <a-select
            v-model="deliver.form.weixinSubMchid"
            show-search
            placeholder="账户名称"
            :default-active-first-option='false'
            :filter-option='false'
            :allowClear='true'
            @search="handleChannelNameSearch"
            @change="handleChannelNameChange"
          >
            <a-select-option v-for='item in  payChannelList' :key='item.mchid'>
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label='余额:' prop='balance' >
          <a-input v-model='deliver.form.balance' />
        </a-form-model-item>



        <a-form-model-item label='收款码' prop='qrImgUrl' >
          <a-upload
            list-type='picture-card'
            :file-list="deliver.form.qrImgUrl ? [{
              uid: '-1',
              name: 'qrImgUrl',
              status: 'done',
              url: deliver.form.qrImgUrl,
            }] : []"
            :customRequest='(event) => {handleUpload(event, "qrImgUrl")}'
            :remove='() => deliver.form.qrImgUrl = ""'>
            <div v-if="!deliver.form.qrImgUrl">
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import {queryAccount, deleteAccount, updateAccount, queryPayChannel, channelAccount} from '@/api/account'
import client from '@/config/oss.config.js'

const columns = [
  {
    width: 150,
    title: '账户名称',
    dataIndex: 'name'
  },
  {
    width: 150,
    title: '简称',
    dataIndex: 'simpleName'
  },
  {
    width: 150,
    title: '商户号',
    dataIndex: 'weixinSubMchid'
  },
  {
    width: 200,
    title: '收款码',
    dataIndex: 'qrImgUrl',
    scopedSlots: { customRender: 'qrImgUrl' }
  },
  {
    width: 150,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'Account',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      loading: false,
      // 表头
      columns,
      payChannelList: [],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return queryAccount(Object.assign(parameter)).then(datum => {
          // debugger
            this.$previewRefresh()
            return {
              records: datum
            }
          })
      },
      deliver: { 
        loading: false,
        visible: false, 
        form: {},
        rules: {
          name: [{ required: true, message: '请填写账户名称', trigger: 'change' }],
          weixinSubMchid: [{ required: true, message: '请填写商户号', trigger: 'change' }],
          simpleName: [{ required: true, message: '请填写简称', trigger: 'change' }],
          legal: [{ required: true, message: '请填写法人代表', trigger: 'change' }],
          address: [{ required: true, message: '请填写地址', trigger: 'change' }],
          accountName: [{ required: true, message: '请填写账户名', trigger: 'change' }],
          accountNo: [{ required: true, message: '请填写银行账户号', trigger: 'change' }],
          accountBank: [{ required: true, message: '请填写开户行', trigger: 'change' }],
          seal: [{ required: true, message: '请填写公章', trigger: 'change' }],
          qrImgUrl: [{ required: true, message: '请上传收款码', trigger: 'change' }],
          paymentChannelType: [{ required: true, message: '请选择支付渠道', trigger: 'change' }],
          ChannelModeType: [{ required: true, message: '请选择去到模式', trigger: 'change' }],
          balance: [{ required: true, message: '请填写月约', trigger: 'change' }],
        }
      },
    }
  },
  created() {
    this.handleInitial()
  },
  methods: {
    async handleUpload(event, key) {
      this.loading = true
      let result = await client.put('account/' + event.file.uid, event.file)
      this.$set(this.deliver.form, key, result.url)
      this.loading = false
      this.$refs.form.clearValidate()
    },
    handleNew(record) {
      let param = {current: 1, size: 50,paymentChannelType:record.paymentChannelType};
      queryPayChannel(param).then(datum => {
        this.payChannelList = datum.records;
        this.deliver.visible = true;
        this.deliver.form = Object.assign({}, record);
        if(record.paymentChannelType!=3){//用户转换参数，因为不同支付渠道类型，渠道号的字段不一样
          for(let index in this.payChannelList){
            // delete  record.weixinSubMchid;
            let item = this.payChannelList[index];
            if(item.mchid === record.weixinSubMchid +''){
              this.deliver.form.weixinSubMchid = record.weixinSubMchid+'';
            }
          }
        }
      })


    },
    handleChannelNameSearch(name){
      let param = {current: 1, size: 50,name:name};
      if(this.deliver.form.paymentChannelType){
        param.paymentChannelType = this.deliver.form.paymentChannelType;
      }
      // debugger
      queryPayChannel(param).then(datum => {
        this.payChannelList = datum.records;
      })
    },
    payChannelChange(e){
      // debugger;
      this.handleChannelNameSearch('');
    },
    handleChannelNameChange(e) {
      this.$set(this.deliver.form, "mName", e);
    },
    handleDeliver() {
      this.$refs.form.validate(result => {
       if (result) {
        this.deliver.loading = true;
        updateAccount(this.deliver.form).then(() => {
          this.$message.success('操作成功')
          this.deliver.visible = false;
          this.$refs.table.refresh(true)
          this.deliver.loading = false;
        }).catch(() => {
          this.deliver.loading = false;
        })
       }
      })
    },
    handleDelete(record) {
      deleteAccount({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    } ,
    handleInitial() {
      this.handleChannelNameSearch('');
    },

  }
}
</script>
